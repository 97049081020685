export function initAccountBalanceListHeader () {
    const columns = [{
        title: '订单详情',
        key: 'orderDetail',
        slot: 'orderDetail'
      },
      {
        title: '金额(元)',
        key: 'price',
        slot: 'price',
				width: 150,
      }
      // {
      //   title: '操作',
      //   slot: 'action',
      //   width: 100,
      //   align: 'center',
      //   fixed: 'right',

      // }
    ]
    return columns
}

export function initFrozenMoneyListHeader () {
    const columns = [{
        title: '订单详情',
        key: 'orderDetail',
        slot: 'orderDetail'
      }, {
        title: '金额(元)',
        key: 'amount',
        slot: 'amount',
				width: 150,
      }]
    return columns
}

export function initWithDrawListHeader () {
  const columns = [{
      title: '提现申请列表',
      key: 'orderDetail',
      slot: 'orderDetail'
    }, {
      title: '金额(元)',
      key: 'amount',
      slot: 'amount',
			width: 150,
    }]
  return columns
}


